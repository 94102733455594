import React from 'react';

import MyPage from 'components/Club/MyPage';
import PageTitleBar from 'components/misc/PageTitleBar';
import TabBar from 'components/misc/TabBar';

const tabBarMenus = [
  { name: '멤버십 안내', link: '/club-artisee/membership/' },
  { name: '마이 아티제', link: '/club-artisee/my-artisee/' },
  { name: '마이 페이지', link: '/club-artisee/my-page/' },
  { name: '자주 묻는 질문', link: '/club-artisee/faq/' },
];

const MyPagePage = () => (
  <div>
    <PageTitleBar title="My page" backgroundImage={require('../../assets/images/banner/club_artisee.jpg')} />
    <TabBar index={2} data={tabBarMenus} />
    <MyPage />
  </div>
);

export default MyPagePage;
