import React from 'react';
import classNames from 'classnames/bind';

import styles from './NotAuth.module.scss';

const cx = classNames.bind(styles);

const login = () => {
  window.open('/popup/login/', 'loginPopup', 'width=600,height=700');
}

const join = () => {
  window.open('/popup/join/', 'joinPopup', 'width=600,height=700');
}

const NotAuth = () => (
  <div className={cx('container')}>
    <img src={require('assets/images/misc/not-auth.png')} alt="Not Found" className={cx('image')} />

    <div className={cx('title')}>로그인 정보를 찾을 수 없습니다.</div>
    <div className={cx('paragraph')}>
      본 페이지는 로그인 후 이용가능합니다.
    </div>
    <div className={cx('buttons')}>
      <div className={cx('button')} onClick={login}>
        <div className={cx('text')}>로그인</div>
      </div>
      <div className={cx('button')} onClick={join}>
        <div className={cx('text')}>회원가입</div>
      </div>
    </div>
  </div>
);

export default NotAuth;