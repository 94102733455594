import React, { Component } from 'react';

import { post } from '../../../api';
import NotAuth from '../NotAuth';
import Confirm from './Confirm';

export default class MyPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: null,
    };
  }

  async componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    // 1. 엑세스토큰 있는 경우
    if (token !== null) {
      const isActiveTokenResult = await post('auth/check', { token });
      console.debug('isActiveTokenResult', isActiveTokenResult);

      // 1-1. 엑세스토큰 유효한 경우
      if (isActiveTokenResult.active) {
        this.setState({isLoggedIn: true});
      }
      // 1-2. 엑세스토큰 만료된 경우
      else {
        this.setState({isLoggedIn: false});
      }
    }
    // 2. 엑세스토큰 없는 경우
    else {
      this.setState({isLoggedIn: false});
    }
  }

  render() {
    const { isLoggedIn } = this.state;

    // 로그인 전
    if (isLoggedIn !== null && !isLoggedIn) {
      return (
        <NotAuth />
      );
    }
    // 로그인 후
    else if (isLoggedIn !== null && isLoggedIn) {
      return (
        <Confirm />
      );
    }

    return null;
  }
}