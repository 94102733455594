import React, { Component } from 'react';
import { Container, Row, Col, ScreenClassRender } from 'react-grid-system';
import classNames from 'classnames/bind';
import Ionicon from 'react-ionicons';
import moment from 'moment';
import 'moment/locale/ko.js';

import { ARTISEE_ID_URL } from '../../../config';
import styles from './Form.module.scss';
import {
  post,
  logout,
  updatePromotionAllowed,
  getChangeAddressTicket,
  getChangeCellphoneTicket,
  getChangePasswordTicket,
  getConfirmPasswordTicket,
} from 'api';
import cellphoneNumberMask from 'utility/cellphoneNumber';

const cx = classNames.bind(styles);

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: null,
      member: null,
    };
  }

  async componentDidMount() {
    const token = sessionStorage.getItem('access_token');

    // 1. 엑세스토큰 있는 경우
    if (token !== null) {
      const result = await post('member/me/form', { token });
      console.log(result);

      if (result.isSuccess) {
        this.setState({
          isLoggedIn: true,
          member: result.data,
        });
      } else {
        this.setState({ isLoggedIn: false });
      }
    }
    // 2. 엑세스토큰 없는 경우
    else {
      this.setState({ isLoggedIn: false });
    }
  }

  render() {
    const { isLoggedIn, member } = this.state;

    // 로그인 전
    if (isLoggedIn !== null && !isLoggedIn) {
      return null;
    }
    // 로그인 후
    else if (isLoggedIn !== null && isLoggedIn) {
      const {
        name,
        email,
        cellphoneNumber,
        joinYmdt,
        address,
        isAllowedSmsPromotion,
        isAllowedEmailPromotion,
      } = member;
      let addressString;
      if (address.zipCode !== null) {
        addressString = `(${address.zipCode}) ${address.address} ${address.detailAddress}`;
      } else {
        addressString = '미입력';
      }

      return (
        <ScreenClassRender
          render={screenClass => {
            const isMobile = ['xs', 'sm', 'md'].includes(screenClass);

            return (
              <div>
                <Container className={cx(['container', { mobile: isMobile }])}>
                  <Row className={cx('section')}>
                    <Col xs={12} lg={10} offset={{ lg: 1 }}>
                      <div className={cx('tableTitle')}>기본정보</div>
                      <div className={cx('table')}>
                        <div className={cx('row')}>
                          <div className={cx('label')}>이름</div>
                          <div className={cx('content')}>{name}</div>
                        </div>
                        <div className={cx('row')}>
                          <div className={cx('label')}>아이디</div>
                          <div className={cx('content')}>{email}</div>
                        </div>
                        <div className={cx('row')}>
                          <div className={cx('label')}>비밀번호</div>
                          <div className={cx('content')}>
                            <div className={cx('editButton')} onClick={this.changePassword}>
                              변경
                            </div>
                          </div>
                        </div>
                        <div className={cx('row')}>
                          <div className={cx('label')}>휴대전화번호</div>
                          <div className={cx('content')}>
                            <div className={cx('value')}>{cellphoneNumberMask(cellphoneNumber)}</div>
                            <div className={cx('editButton')} onClick={this.changeCellphone}>
                              변경
                            </div>
                          </div>
                        </div>
                        <div className={cx('row')}>
                          <div className={cx('label')}>가입일자</div>
                          <div className={cx('content')}>{moment(joinYmdt).format('YYYY.MM.DD')}</div>
                        </div>
                      </div>
                      <div className={cx('buttonWrapper')}>
                        <div className={cx('button')} onClick={this.logout}>
                          <div className={cx('text')}>로그아웃</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className={cx('section')}>
                    <Col xs={12} lg={10} offset={{ lg: 1 }}>
                      <div className={cx('tableTitle')}>선택정보</div>
                      <div className={cx('table')}>
                        <div className={cx('row')}>
                          <div className={cx('label')}>주소</div>
                          <div className={cx('content')}>
                            <div className={cx('value')}>{addressString}</div>
                            <div className={cx('editButton')} onClick={this.changeAddress}>
                              변경
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className={cx('section')}>
                    <Col xs={12} lg={10} offset={{ lg: 1 }}>
                      <div className={cx('tableTitle')}>마케팅정보수신</div>
                      <div className={cx('table')}>
                        <div className={cx('row')}>
                          <div className={cx('label')}>SMS 수신</div>
                          <div className={cx('content')}>
                            {this.getRadioItem(
                              isAllowedSmsPromotion,
                              '동의',
                              this.toggleAllowedSmsPromotion.bind(this, true),
                            )}
                            {this.getRadioItem(
                              !isAllowedSmsPromotion,
                              '거부',
                              this.toggleAllowedSmsPromotion.bind(this, false),
                            )}
                          </div>
                        </div>
                        <div className={cx('row')}>
                          <div className={cx('label')}>이메일 수신</div>
                          <div className={cx('content')}>
                            {this.getRadioItem(
                              isAllowedEmailPromotion,
                              '동의',
                              this.toggleAllowedEmailPromotion.bind(this, true),
                            )}
                            {this.getRadioItem(
                              !isAllowedEmailPromotion,
                              '거부',
                              this.toggleAllowedEmailPromotion.bind(this, false),
                            )}
                          </div>
                        </div>
                      </div>
                      <div className={cx('buttonWrapper')}>
                        <div className={cx('button')} onClick={this.updatePromotionAllowed}>
                          <div className={cx('text')}>변경</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className={cx('section')}>
                    <Col xs={12} lg={10} offset={{ lg: 1 }}>
                      <div className={cx('tableTitle')}>탈퇴 유의사항</div>
                      <div className={cx('table')}>
                        <div className={cx('row')}>
                          <ul className={cx('list')}>
                            <li>
                              회원 탈퇴를 한 날로부터 <strong>30일이 경과 해야만 재가입이 가능</strong>합니다.
                            </li>
                            <li>
                              탈퇴 시 보유하고 계신 <strong>쿠폰과 club artisée 포인트, 스탬프는 즉시 자동 소멸</strong>
                              됩니다.
                            </li>
                            <li>
                              진행 중인 전자상거래 이용내역이 있거나 이용하신 서비스가 완료되지 않은 경우 탈퇴하실 수
                              없습니다.
                            </li>
                            <li>
                              그 동안 회원님께서 남겨주신 <strong>모든 게시물과 누적 정보들은 삭제 처리</strong>됩니다.
                            </li>
                            <li>
                              입력하신 정보는 회원탈퇴를 위한 목적으로만 사용되며, 절대 다른 목적으로 이용되지 않습니다.
                            </li>
                            <li>
                              탈퇴 후 <strong>30일간</strong> 재가입 방지 및 포인트 불법 이용 및 부정 행위를 방지하기
                              위해 회원님의 아이디를 포함한 <strong>회원정보가 보존</strong>됩니다.
                              <br />
                              (회원가입 시 동의하신 개인정보 처리방침에 명시한 파기절차와 방법에 따라 30일 이후
                              회원정보를 지체없이 파기합니다.)
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className={cx('buttonWrapper')}>
                        <div className={cx('button')} onClick={this.doWithdraw}>
                          <div className={cx('text')}>회원탈퇴</div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            );
          }}
        />
      );
    }

    return null;
  }

  getRadioItem = (isOn, label, onPress) => {
    const icon = isOn ? 'md-radio-button-on' : 'md-radio-button-off';
    const color = isOn ? 'rgb(247, 144, 30)' : 'black';

    return (
      <div className={cx('radioItem')} onClick={onPress}>
        <Ionicon icon={icon} fontSize="21px" color={color} />
        <div className={cx('radioLabel')}>{label}</div>
      </div>
    );
  };

  // 로그아웃
  logout = async () => {
    const logoutResult = await logout();

    // success
    if ('logoutStatus' in logoutResult && logoutResult.logoutStatus === 'LOGOUT_SUCCESS') {
      sessionStorage.clear();
      window.location.replace('/');
    }
    // failure
    else {
      alert('예기지 않은 문제가 발생하였습니다.');
    }
  };

  // SMS 수신동의여부 선택
  toggleAllowedSmsPromotion = allow => {
    this.setState({
      isAllowedSmsPromotion: allow,
    });
  };

  // 이메일 수신동의여부 선택
  toggleAllowedEmailPromotion = allow => {
    this.setState({
      isAllowedEmailPromotion: allow,
    });
  };

  // 마케팅정보수신동의변경
  updatePromotionAllowed = async () => {
    const { isAllowedSmsPromotion, isAllowedEmailPromotion } = this.state;
    const updatePromotionAllowedResult = await updatePromotionAllowed(isAllowedSmsPromotion, isAllowedEmailPromotion);

    if (updatePromotionAllowedResult.header.isSuccessful) {
      alert('마케팅정보수신 정보가 변경되었습니다.');
    } else {
      alert('예기치 않은 문제가 발생하였습니다.');
    }
  };

  // 비밀번호변경
  changePassword = async () => {
    // https://m.blog.naver.com/leokevin/220947201454
    const changePasswordWindow = window.open('about:blank', 'changePasswordWindow', 'width=600,height=700');

    const changePasswordTicketResult = await getChangePasswordTicket();
    const { ticket } = changePasswordTicketResult.data;
    changePasswordWindow.location.href = `${ARTISEE_ID_URL}/change-password?ticket=${ticket}&appYn=N&logoYn=Y`;
  };

  // 휴대전화번호변경
  changeCellphone = async () => {
    const changeCellphoneWindow = window.open('about:blank', 'changeCellphoneWindow', 'width=600,height=700');

    const changeCellphoneTicketResult = await getChangeCellphoneTicket();
    const { ticket } = changeCellphoneTicketResult.data;
    changeCellphoneWindow.location.href = `${ARTISEE_ID_URL}/change-cellphone-number?ticket=${ticket}&appYn=N&logoYn=Y`;
  };

  // 주소변경
  changeAddress = async () => {
    const changeAddressWindow = window.open('about:blank', 'changeAddressWindow', 'width=600,height=700');

    const changeAddressTicketResult = await getChangeAddressTicket();
    const { ticket } = changeAddressTicketResult.data;
    changeAddressWindow.location.href = `${ARTISEE_ID_URL}/change-address?ticket=${ticket}&appYn=N&logoYn=Y`;
  };

  // 회원탈퇴
  doWithdraw = async () => {
    // 충전금 잔여여부 검사
    const token = sessionStorage.getItem('access_token');
    const result = await post('user/withdrawable', { token });

    if (result.success) {
      // 회원탈퇴 가능한 경우
      if (result.data.withdrawAbleYN === 'Y') {
        const confirmPasswordWindow = window.open('about:blank', 'confirmPasswordWindow', 'width=600,height=700');

        const confirmPasswordTicketResult = await getConfirmPasswordTicket('withdraw');
        const { ticket } = confirmPasswordTicketResult.data;
        confirmPasswordWindow.location.href = `${ARTISEE_ID_URL}/confirm-password?ticket=${ticket}&appYn=N&logoYn=Y`;
      }
      // 회원탈퇴 불가능한 경우
      else {
        alert(result.data.disapprovalReason);
      }
    }
  };
}
