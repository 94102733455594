export default (cellphone) => {
  try {
    if (cellphone === '' || cellphone === null || cellphone === undefined) {
      return cellphone;
    }
  
    if (cellphone.startsWith('+82')) {
      let number = `0${cellphone.substring(3)}`;

      switch (number.length) {
        case 10:
        number = `${number.substr(0, 3)}-${number.substr(3, 3)}-${number.substr(6)}`;
        break;

        case 11:
        number = `${number.substr(0, 3)}-${number.substr(3, 4)}-${number.substr(7)}`;
        break;

        default:
        break;
      }

      return number;
    }

    return cellphone;
  }
  catch (e) {
    return cellphone;
  }
}