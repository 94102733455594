import React, { Component } from 'react';
import moment from 'moment';
import CryptoJS from 'crypto-js';

import { ARTISEE_ID_URL } from '../../../config';
import Form from './Form';
import { getConfirmPasswordTicket } from 'api';

class Confirm extends Component {
  constructor(props) {
    super(props);

    let isValid = false;
    const encTimestamp = sessionStorage.getItem('confirm_byte');

    // 암호화 된 Timestamp가 있는 경우
    if (encTimestamp !== null) {
      const confirmTimestamp = CryptoJS.AES.decrypt(encTimestamp, 'arts').toString(CryptoJS.enc.Utf8); // Timestamp 복호화
      const confirmMoment = moment(confirmTimestamp, 'YYYYMMDDHHmmss');

      isValid = 900 > moment().diff(confirmMoment, 'seconds'); // 비밀번호 확인 후 900초 내에만 유효
    }

    this.state = {
      isValid,
      iframeSrc: null,
    };
  }

  async componentDidMount() {
    const { isValid } = this.state;

    if (!isValid) {
      const confirmPasswordTicketResult = await getConfirmPasswordTicket('query');
      const { ticket } = confirmPasswordTicketResult.data;

      this.setState({
        iframeSrc: `${ARTISEE_ID_URL}/confirm-password?ticket=${ticket}&appYn=N&logoYn=N`,
      });
    }
  }

  render() {
    const { isValid, iframeSrc } = this.state;

    if (!isValid && iframeSrc !== null) {
      return (
        <iframe
          title="비밀번호확인"
          src={iframeSrc}
          scrolling="no"
          frameBorder="0"
          width="100%"
          height="440px"
          style={{ marginTop: 80 }}
        />
      );
    } else if (isValid) {
      return <Form />;
    }

    return null;
  }
}

export default Confirm;
